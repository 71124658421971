import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useFetchQuerySelector from 'app/hooks/useFetchQuerySelector'

import {
    LaneSelector,
} from 'app/Apps/Pricing/Pricing.types'
import config from 'app/Apps/Pricing/LaneGroups/Lanes/Lane.requests'
import getEnumLabel from 'app/utils/getEnumLabel'

export const convert = ({
    data,
}) => {
    return (data || []).map(({
        laneNumber,
        leaseType,
        id,
        customerExecutionLocationName,
        handoverPointLocationName,
        collectionDropoffPointLocationName,
        originScId,
        originScLocationName,
    }) => {
        return {
            label: `${laneNumber} | ${getEnumLabel(leaseType)} | ${customerExecutionLocationName}`,
            id,
            value: id,
            customerExecutionLocationName,
            leaseType,
            handoverPointLocationName,
            collectionDropoffPointLocationName,
            originScId,
            originScLocationName,
        }
    })
}

export default () => {
    return useFetchQuerySelector<LaneSelector[]>({
        key: RequestKeys.getLanes,
        selectorConfig: config.selector,
        convert,
    })
}
