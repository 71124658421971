import React from 'react'
import noop from 'lodash/noop'

import Button from 'app/shared-components/Button'

import useStyles from './SkyNetStepperButtons.styles'

type Props = {
    isValid: boolean,
    canGoBack?: boolean,
    isLoading?: boolean,
    disabled: boolean,
    nextAction?: () => void,
    backAction?: () => void,
    nextLabel?: string,
    backLabel?: string,
}

const defaultProps: Partial<Props> = {
    canGoBack: false,
    isLoading: false,
    nextAction: noop,
    backAction: noop,
    nextLabel: undefined,
    backLabel: undefined,
}

const SkyNetStepperButtons = ({
    isValid,
    canGoBack,
    disabled,
    nextAction,
    backAction,
    nextLabel,
    backLabel,
    isLoading,
}:Props) => {
    const {
        classes,
        cx,
    } = useStyles()

    return (
        <div className={cx(classes.buttonWrapper, classes.doubleButtons)}>
            <Button
                label={nextLabel || 'Next'}
                onClick={nextAction}
                className={classes.button}
                disabled={disabled || !isValid}
                saving={isLoading}
            />
            {canGoBack && (
                <Button
                    label={backLabel || 'Back'}
                    secondary
                    onClick={backAction}
                    className={classes.button}
                    data-testid="btn-back"
                />
            )}
        </div>
    )
}

SkyNetStepperButtons.defaultProps = defaultProps

export default SkyNetStepperButtons
