import React, {
    useCallback, useState, useEffect,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import useGetContractBasis from 'app/Apps/Pricing/ContractBasis/hooks/useGetContractBasis'
import Button from 'app/shared-components/Button'
import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'
import QuoteInformationForm from '../QuoteInformationForm'
import requests from '../AccountQuotes.requests'
import {
    AccountQuote,
    QuoteStatus,
} from '../AccountQuotes.types'
import useAssignToContractBasis from './hooks/useAssignToContractBasis'

const AssignQuote = ({
    data,
    invalidate,
}: {
    data: AccountQuote,
    invalidate: () => void
}) => {
    const [
        value,
        setValue,
    ] = useState(data)

    const [
        defaultContractBasis,
        setDefaultContractBasis,
    ] = useState(value?.plannedContractBasis)

    const {
        data: contractBasis,
        isDataReady: isDataReadyContractBasis,
    } = useGetContractBasis(
        value?.quoteStatus === QuoteStatus.CREATED ? value?.plannedContractBasis?.id : null,
    )

    useEffect(() => {
        if (
            contractBasis && value?.plannedContractBasis?.id !== defaultContractBasis?.id
            && contractBasis?.id === value?.plannedContractBasis?.id
        ) {
            setDefaultContractBasis(value?.plannedContractBasis)
            setValue({
                ...value,
                currency: contractBasis?.currency,
            })
        }
    }, [
        contractBasis,
        defaultContractBasis?.id,
        value,
        isDataReadyContractBasis,
    ])

    const {
        updateTableElement,
    } = useDomainObjectContext()

    const onSuccess = useCallback(() => {
        invalidate()
        updateTableElement({
            ...value,
        })
    }, [
        updateTableElement,
        value,
        invalidate,
    ])

    const assignToContractBasis = useAssignToContractBasis({
        id: data.id,
        onSuccess,
    })

    const getCustomButtons = useCallback(() => {
        const customButton = (
            <Button
                key="assing-to-contract-basis"
                label={data?.quoteStatus === QuoteStatus.CREATED ? 'Assign To Contract Basis' : 'Reassign To Contract Basis'}
                onClick={assignToContractBasis}
                disabled={data?.linkedContractBasis?.id
                    === data?.plannedContractBasis?.id}
            />
        )

        return (data?.quoteStatus === QuoteStatus.CREATED
            || data?.quoteStatus === QuoteStatus.IN_PROGRESS)
        && data.plannedContractBasis?.id ? [customButton] : []
    }, [
        data?.quoteStatus,
        data?.linkedContractBasis,
        data?.plannedContractBasis,
        assignToContractBasis,
    ])

    return (
        <QuoteInformationForm
            value={value}
            setValue={setValue}
            showContractBasisLink
            render={({
                fields, formData, onChange,
            }) => {
                return (
                    <FormWithControls
                        setExternalValue={onChange}
                        value={formData}
                        name="AccountQuote"
                        requestParams={requests.update({
                            id: data.id,
                        })}
                        fields={fields}
                        onSuccess={invalidate}
                        onCancel={invalidate}
                        exists
                        getCustomButtons={getCustomButtons}
                    />
                )
            }}
        />
    )
}

export default AssignQuote
