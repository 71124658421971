import {
    ProductType,
} from 'app/types/enums'
import isEmpty from 'lodash/isEmpty'
import {
    RequiredContainersCustomer,
} from 'app/types/common.types'
import {
    PreBookingEntitySpreadsheetData,
} from './PreBookingSpreadsheet.config'

type RequiredProductTypes = [ProductType, number][]

export const formatRequiredContainers = (productTypes: RequiredProductTypes) => {
    return productTypes.reduce((acc, [
        key,
        quantity,
    ]) => {
        if (quantity) {
            return [
                ...acc,
                {
                    productType: key,
                    quantity,
                },
            ]
        }
        return acc
    }, [] as RequiredContainersCustomer[])
}

export const convertToPrebookingEntity = (value: PreBookingEntitySpreadsheetData[]) => {
    return value.map(({
        lane,
        requestedHandover,
        handoverTimezone,
        requestedPickup,
        pickupTimezone,
        requestedHandoverWeek,
        requestedHandoverYear,
        [ProductType.VAL_1500C]: productTypeVAL1500C,
        [ProductType.VAL_1500XCOL]: productTypeVAL1500XCOL,
        [ProductType.VAL_2500C]: productTypeVAL2500C,
        mawbNumber,
        customerReference,
        preBookingStatus,
        containersStatus,
        isForecasted,
        hasBooking,
        project,
        originServiceCenter,
    }) => {
        return {
            lane,
            requestedHandover: isEmpty(requestedHandover) ? undefined : `${requestedHandover} ${handoverTimezone}`,
            requestedPickup: isEmpty(requestedPickup) ? undefined : `${requestedPickup} ${pickupTimezone}`,
            requestedHandoverWeek,
            requestedHandoverYear,
            mawbNumber: isEmpty(mawbNumber) ? undefined : mawbNumber,
            customerReference,
            preBookingStatus,
            containersStatus,
            isForecasted,
            hasBooking,
            project,
            originServiceCenter,
            requiredContainers: formatRequiredContainers([
                [
                    ProductType.VAL_1500C,
                    productTypeVAL1500C,
                ],
                [
                    ProductType.VAL_1500XCOL,
                    productTypeVAL1500XCOL,
                ],
                [
                    ProductType.VAL_2500C,
                    productTypeVAL2500C,
                ],
            ]),
        }
    })
}
