import {
    WithId,
    RequiredContainersCustomer,
} from 'app/types/common.types'
import {
    LeaseType,
} from 'app/types/enums'
import {
    CollServiceType,
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    LaneStatus,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'

export type PreBooking = {
    preBookingNumber: string,
    laneNumber: string,
    pricingAccountCompanyName: string,
    originAirportIataCode: string,
    destinationAirportIataCode: string,
    hasBooking: boolean,
    requestedHandover: string,
    containersStatus: PreBookingContainerStatus,
    preBookingStatus: PreBookingStatus,
    requiredContainers: string[],
    customerExecution: string,
    destinationCountryName: string,
}

export type PreBookingEntity = {
    id?: number,
    preBookingNumber?: string,
    containersStatus: PreBookingContainerStatus,
    customerExecution: WithId<number>,
    customerReference: string,
    destinationAirport: WithId<number>,
    destinationCountry: WithId<number>,
    hasBooking: boolean,
    isForecasted: boolean,
    lane: WithId<number>,
    mawbNumber: string,
    originAirport: WithId<number>,
    originCountry: WithId<number>,
    originServiceCenter:WithId<number>,
    preBookingStatus: PreBookingStatus,
    pricingAccount: WithId<number>,
    project: string,
    requestedHandover: string,
    requestedHandoverWeek: number,
    requestedHandoverYear: number,
    requestedPickup: string,
    requiredContainers: RequiredContainersCustomer[],
}

export enum PreBookingStatus {
    PENDING = 'PENDING',
    REJECTED_SKYCELL = 'REJECTED_SKYCELL',
    CANCELLED_CUSTOMER = 'CANCELLED_CUSTOMER',
    ORDER_CREATED = 'ORDER_CREATED',
}
export enum PreBookingReasonForChange {
    MANUAL_ERROR = 'MANUAL_ERROR',
    CUSTOMER = 'CUSTOMER',
    INTERNAL = 'INTERNAL',
}
export enum PreBookingContainerStatus {
    PENDING = 'PENDING',
    CONTAINERS_AVAILABLE = 'CONTAINERS_AVAILABLE',
    CONTAINERS_UNAVAILABLE = 'CONTAINERS_UNAVAILABLE',
}

export type PreBookingsTableType = PreBookingEntity & PreBooking

export type PreBookingAvailableLanes = WithId<number> & {
    laneNumber: string,
    customerExecutionLocationName: string,
    accountCompanyCompanyName: string,
    consigneeLocationName: string,
    deliveryServiceType: DeliveryServiceType,
    collectionServiceType: CollServiceType,
    originAirportIataCode: string,
    destAirportIataCode: string,
    laneStatus: LaneStatus,
    leaseType: LeaseType,
    handoverPointLocationName: string,
    collectionDropoffPointLocationName: string,
    tempRange: string,
}

export type RequiredContainersChange = {
    id: number,
    modifiedOn: string,
    modificationReason: PreBookingReasonForChange,
    modifiedBy: string,
    preBookingId: number,
    previousRequiredContainers: RequiredContainersCustomer[],
    requiredContainers: RequiredContainersCustomer[]
}
