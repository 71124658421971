import React from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable/SkyNetTableContainer'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import tableConfig from './serviceCenters.config'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const ServiceCenters = () => {
    return (
        <SkyNetTable
            name={tableConfig.name}
            defaultTab="overview"
            tableControls={tableControls}
            tableConfig={tableConfig}
            tabs={[]}
            showSearch
        />
    )
}

export default ServiceCenters
