import getEnumLabel from 'app/utils/getEnumLabel'
import {
    RequestConfig,
    RequestForDomainForm, SelectorConfig,
} from 'app/types/request.types'
import {
    LaneStatus,
} from 'app/types/enums'
import {
    LaneSelector,
} from '../../Pricing.types'

type LaneRequests = {
    domainName: string,
    create: RequestForDomainForm<{laneGroupId: number}>,
    update: RequestForDomainForm<{laneId: number}>,
    getLane: ({
        laneId,
    }) => RequestConfig,
    getLaneByLaneGroup: ({
        laneGroupId,
        params,
    }: {
        laneGroupId: number,
        params?: any
    }) => RequestConfig,
    filterRequest: ({
        laneGroupId,
    }) => string,
    duplicate: (
        laneId,
    ) => RequestConfig,
    archive: ({
        id: number,
    }) => RequestConfig,
    restore: ({
        id: number,
    }) => RequestConfig,
    status: ({
        laneStatus,
        lanes,
    }: {
        laneStatus: LaneStatus,
        lanes: {id: number}[]
    }) => RequestConfig,
    selector: (params) => SelectorConfig<LaneSelector[]>,
}

const laneRequests: LaneRequests = {
    domainName: 'Lane',
    create: {
        name: 'LaneCreateNewDto',
        request: ({
            laneGroupId,
        }) => {
            return {
                url: `lanegroup/${laneGroupId}/lane`,
                method: 'POST',
                domainName: 'LaneCreateNewDto',
            }
        },
    },
    update: {
        name: 'LaneUpdateNewDto',
        request: ({
            laneId,
        }: {laneId: number}) => {
            return {
                url: `lane/${laneId}`,
                method: 'PATCH',
                domainName: 'LaneUpdateNewDto',
            }
        },
    },
    getLane: ({
        laneId,
    }) => {
        return {
            url: `lane/${laneId}`,
            method: 'GET',
        }
    },
    getLaneByLaneGroup: ({
        laneGroupId,
        params,
    }) => {
        return {
            url: `lanegroup/${laneGroupId}/lane/filter-request/all`,
            method: 'POST',
            ...params,
        }
    },
    filterRequest: ({
        laneGroupId,
    }) => {
        return `lanegroup/${laneGroupId}/lane/filter-request/all`
    },
    duplicate: (laneId) => {
        return {
            url: `lane/${laneId}/duplication`,
            method: 'POST',
        }
    },
    archive: ({
        id,
    }) => {
        return {
            url: `lane/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `lane/${id}/restore`,
            method: 'PATCH',
        }
    },
    status: ({
        laneStatus,
        lanes,
    }) => {
        return {
            url: 'lane/status',
            method: 'PATCH',
            data: {
                laneStatus,
                lanes,
            },
        }
    },
    selector: ({
        includeFilters,
        ...rest
    } = {}) => {
        return {
            url: 'lane/filter-request/selector',
            method: 'POST',
            convertion: (lanes) => {
                return lanes.map(({
                    laneNumber,
                    leaseType,
                    id,
                    customerExecutionLocationName,
                    handoverPointLocationName,
                    collectionDropoffPointLocationName,
                }) => {
                    return {
                        label: `${laneNumber} | ${getEnumLabel(leaseType)} | ${customerExecutionLocationName}`,
                        id,
                        value: id,
                        customerExecutionLocationName,
                        leaseType,
                        handoverPointLocationName,
                        collectionDropoffPointLocationName,
                    }
                })
            },
            ...rest,
            includeFilters,
        }
    },
}

export default laneRequests
