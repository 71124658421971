import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    ServiceCentersTableType,
    ServiceCenterStatus, ServiceCenterType,
} from './serviceCenters.types'

const config: SkyNetTableConfig<keyof ServiceCentersTableType> = {
    name: 'ServiceCenter',
    tabs: [{
        name: 'All Service Centers',
        url: 'all',
    }],
    fields: [
        {
            name: 'serviceCenterNumber',
            labelKey: 'ServiceCenters.serviceCenterNumber',
            reduced: true,
        },
        {
            name: 'locationLocationName',
            filterField: 'locationLocationName',
            filterType: FilterType.Array,
            labelKey: 'ServiceCenters.location',
            reduced: true,
        },
        {
            name: 'serviceCenterStatus',
            filterField: 'serviceCenterStatus',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: ServiceCenterStatus,
            labelKey: 'ServiceCenters.serviceCenterStatus',
        },
        {
            name: 'serviceCenterType',
            filterField: 'serviceCenterType',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: ServiceCenterType,
            labelKey: 'ServiceCenters.serviceCenterType',
        },
        {
            name: 'operationalDays',
            filterField: 'operationalDays',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: ServiceCenterType,
            labelKey: 'ServiceCenters.operationalDays',
        },
        {
            name: 'orderSelectionDelay',
            labelKey: 'ServiceCenters.orderSelectionDelay',
        },
    ],
}

export default config
