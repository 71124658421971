import React from 'react'

const PreBookingBulkEdit = ({
    backUrl,
}: Readonly<{backUrl: string}>) => {
    // eslint-disable-next-line no-console
    console.log('backUrl', backUrl)
    return <div>PreBooking Bulk Edit</div>
}

export default PreBookingBulkEdit
