import React, {
    useMemo,
} from 'react'
import useHasPermissionType from 'app/Apps/ContactManagement/utils/useHasPermissionType'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    App,
} from 'app/types/common.enums'

import StatusHandler from 'app/shared-components/StatusHandler'
import {
    ArchiveButton,
    ArchiveConfirmationDialog,
    useArchive,
} from 'app/shared-components/Archive'
import ContactForm from '../ContactForm'
import useGetContact from '../hooks/useGetContact'
import request from '../Contacts.request'

const ContactUpdate = ({
    id,
}: { id: number }) => {
    const {
        data,
        isDataReady,
        error,
        isError,
        invalidate,
        isFetching,
    } = useGetContact(id)

    const archive = useArchive({
        requestParamFn: data?.archived ? request.restore
            : request.archive,
        notificationId: 'Contact',
        title: 'Contact',
        data,
        redirectionAfterSuccess: data?.isProspect ? `/apps/${App.CONTACT_MANAGEMENT}/contacts/prospect`
            : `/apps/${App.CONTACT_MANAGEMENT}/contacts/all`,
    })
    const hasEditPermissions = useHasPermission(['location_sales'])
    const hasEditTypesPermissions = useHasPermissionType(data?.mainType, 'ContactAccessRights', 'edit')

    const calculatedForbiddenEdit = useMemo(() => {
        if (data?.isProspect) {
            return !hasEditPermissions
        }

        return data?.mainType?.length ? !hasEditTypesPermissions : false
    }, [
        data?.isProspect,
        data?.mainType,
        hasEditPermissions,
        hasEditTypesPermissions,
    ])

    const customButtons = useMemo(() => {
        return hasEditPermissions ? [<ArchiveButton
            key="archive-button"
            onClick={archive.handleArchiveRestore}
            archived={data?.archived}
        />] : []
    }, [
        hasEditPermissions,
        archive,
        data,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <ContactForm
                    data={data}
                    disabled={calculatedForbiddenEdit}
                    request={request.update()}
                    onSuccess={invalidate}
                    onCancel={invalidate}
                    customButtons={customButtons}
                />
                <ArchiveConfirmationDialog
                    title="Contact"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
            </>
        </StatusHandler>
    )
}

export default ContactUpdate
