import {
    SkyNetSpreadSheetCellType,
    Domain,
} from './SkyNetSpreadSheet.types'
import {
    DelayedLoadOptions,
} from '../../hooks/useDelayedLoadOptions'

const getTextProps = (value) => {
    return {
        text: value ? String(value) : '',
    }
}

const getIdProps = (value) => {
    return {
        id: value,
        text: value,
        value,
    }
}

const getSelectableCellProps = (value, {
    getAllowedValues, item,
}) => {
    return {
        getAllowedValues: getAllowedValues
            ? () => { return getAllowedValues(item) } : undefined,
    }
}

const getNumberProps = (value, {
    format,
}) => {
    return {
        value: value !== null || value !== undefined ? Number(value) : null,
        format: format || new Intl.NumberFormat('en-IN', {
            useGrouping: false,
        }),
    }
}

const getTypeableCellProps = (value, {
    loadOptions,
    domain,
    applyFilters,
    item,
}) => {
    return {
        text: value?.label || value?.text,
        id: value?.id,
        loadOptions,
        domain,
        filters: applyFilters ? applyFilters(item) : undefined,
    }
}

const getDateCellProps = (value, {
    defaultValue,
}) => {
    return {
        text: value,
        defaultValue,
    }
}

function getCellPropsByType({
    value,
    getAllowedValues,
    loadOptions,
    applyFilters,
    domain,
    name,
    format,
    defaultValue,
    type,
    item,
    renderYesNoSelect,
    noneOption,
}: {
    value?: any,
    getAllowedValues?: (...args: any[]) => Record<string, string> | [any, string][],
    loadOptions: (options: DelayedLoadOptions) => Promise<Record<string, any>[]>,
    applyFilters?: (item: Record<string, any>) => Record<string, any>,
    domain?: Domain,
    name?: string,
    format?: string,
    defaultValue?: any,
    type: SkyNetSpreadSheetCellType,
    item: Record<string, any>,
    renderYesNoSelect?: boolean,
    noneOption?: boolean,
}) {
    const props = {
        name,
        ...getTextProps(value),
    }

    switch (type) {
    case SkyNetSpreadSheetCellType.TEXT:
        return {
            ...props,
            ...getTextProps(value),
        }
    case SkyNetSpreadSheetCellType.CHECKBOX:
        return {
            ...props,
            renderYesNoSelect,
            checked: Boolean(value),
        }
    case SkyNetSpreadSheetCellType.ID:
        return {
            ...props,
            ...getIdProps(value),
        }
    case SkyNetSpreadSheetCellType.SELECT:
        return {
            ...props,
            ...getSelectableCellProps(value, {
                getAllowedValues, item,
            }),
            selectedValue: value ? String(value) : '',
            noneOption: noneOption ?? true,
        }
    case SkyNetSpreadSheetCellType.MULTIPLE_SELECT:
        return {
            ...props,
            ...getSelectableCellProps(value, {
                getAllowedValues, item,
            }),
            selectedValue: value || [],
        }
    case SkyNetSpreadSheetCellType.PERCENTAGE:
    case SkyNetSpreadSheetCellType.NUMBER:
        return {
            ...props,
            ...getNumberProps(value || defaultValue, {
                format,
            }),
        }
    case SkyNetSpreadSheetCellType.TYPEAHEAD:
        return {
            ...props,
            ...getTypeableCellProps(value, {
                loadOptions, domain, applyFilters, item,
            }),
            selectedValue: value || {},
        }
    case SkyNetSpreadSheetCellType.MULTIPLE_TYPEAHEAD: return {
        ...props,
        ...getTypeableCellProps(value, {
            loadOptions, domain, applyFilters, item,
        }),
        text: undefined,
        selectedValue: value || [],
    }
    case SkyNetSpreadSheetCellType.DATE:
    case SkyNetSpreadSheetCellType.TIME:
    case SkyNetSpreadSheetCellType.DATETIME: return {
        ...props,
        ...getDateCellProps(value, {
            defaultValue,
        }),
    }
    case SkyNetSpreadSheetCellType.NO_VALUE:
    default: return props
    }
}

export default getCellPropsByType
