import {
    RequestConfig,
} from 'app/types/request.types'
import {
    AccountLane,
} from './AccountLanes.types'

type AccountLanesRequests = {
    domainName: string,
    filterRequest: ({
        params,
    }
    ?: Record<string, any>) => RequestConfig,
    get: ({
        id: number,
    }) => RequestConfig,
    getRequestAwardedLaneProductTypes:({
        id: number,
    }) => RequestConfig,
    getAll: string,
    update: ({
        id: number,
    }) => RequestConfig,
    create: () => RequestConfig,
    quote: ({
        id: number,
    }) => RequestConfig,
    assignToLaneGroup: ({
        id: number,
    }) => RequestConfig,
    rejectPricing: ({
        id: number,
    }) => RequestConfig,
    award: ({
        id: number,
    }) => RequestConfig,
    reject: ({
        id: number,
    }) => RequestConfig,
    updateAwardRejectLaneData: ({
        id,
    }) => RequestConfig,
    bulkCreate: ({
        data,
    }: {
        data: { lanes: AccountLane[] },
    }) => RequestConfig,
    archive: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    restore: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    cancellation: ({
        id,
    }) => RequestConfig,
}

const request: AccountLanesRequests = {
    domainName: 'Account',
    filterRequest: ({
        params = {},
    } = {}) => {
        return {
            url: 'lane/filter-request/all-account',
            method: 'POST',
            ...params,
        }
    },
    getAll: 'lane/filter-request/all-account',
    get: ({
        id,
    }) => {
        return {
            url: `lane/${id}/account-data`,
        }
    },
    getRequestAwardedLaneProductTypes: ({
        id,
    }) => {
        return {
            url: `lane/${id}/producttype/requested-awarded`,
        }
    },
    update: ({
        id,
    }) => {
        return {
            url: `lane/${id}/account`,
            method: 'PATCH',
        }
    },
    create: () => {
        return {
            url: 'lane/by-account',
            method: 'POST',
        }
    },
    quote: ({
        id,
    }) => {
        return {
            url: `lane/${id}/quote`,
            method: 'PATCH',
        }
    },
    assignToLaneGroup: ({
        id,
    }) => {
        return {
            url: `lane/${id}/assignment-to-lanegroup`,
            method: 'PATCH',
        }
    },
    rejectPricing: ({
        id,
    }) => {
        return {
            url: `lane/${id}/lanestatus/rejected-pricing`,
            method: 'PATCH',
        }
    },
    award: ({
        id,
    }) => {
        return {
            url: `lane/${id}/lanestatus/awarded`,
            method: 'PATCH',
        }
    },
    reject: ({
        id,
    }) => {
        return {
            url: `lane/${id}/lanestatus/rejected-customer`,
            method: 'PATCH',
        }
    },
    updateAwardRejectLaneData: ({
        id,
    }) => {
        return {
            url: `lane/${id}/awardreject-data`,
            method: 'PATCH',
        }
    },
    bulkCreate: ({
        data,
    }) => {
        return {
            url: 'lane/by-account/bulk',
            method: 'POST',
            data,
        }
    },
    archive: ({
        id,
    }) => {
        return {
            url: `lane/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `lane/${id}/restore`,
            method: 'PATCH',
        }
    },
    cancellation: ({
        id,
    }) => {
        return {
            url: `lane/${id}/lanestatus/cancelled`,
            method: 'PATCH',
            domainName: 'Lane',
        }
    },
}

export default request
